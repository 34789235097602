import * as React from "react";
import Head from "next/head";
// import Auth from "@/modules/Auth";

import dynamic from "next/dynamic";

const Auth = dynamic(() => import("@/modules/Auth"), {
  ssr: false,
});

const Login = () => {
  return (
    <>
      <Head>
        {process.env.NEXT_PUBLIC_APP_ENV !== "production" && (
          <meta
            name="robots"
            content="nofollow, noindex, noimageindex, noarchive, nosnippet"
          />
        )}
      </Head>
      <Auth />
    </>
  );
};

export default Login;
